import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";

// material ui import
import {
  AppBar,
  Stack,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  Box,
  List,
  ListSubheader,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Divider,
  useMediaQuery,
  Menu,
  MenuItem,
  styled,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CircleIcon from "@mui/icons-material/Circle";
import ArticleIcon from "@mui/icons-material/Article";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';

// local import
import WhiteBrandLogo from "../../assets/white-brand-logo.svg";
import WhiteInitLogo from "../../assets/st-white-init-logo.png";
import { removeFromLocalStorage, getUser, setToLocalStorage } from "../../utils/localStorage";
import { replaceAndSpace } from "../../utils/Common";
import { useDataContext } from "../../context/DataContext";
import DashboardIcon from "../../assets/menu/dashboard-icon.svg";
import AttendanceSummaryIcon from "../../assets/menu/attendance-summary-icon.svg";
import FileRequestIcon from "../../assets/menu/file-request-icon.svg";
import LmsIcon from "../../assets/menu/lms-icon.svg";
import LogoutIcon from "../../assets/menu/logout-icon.svg";
import MyDocumentsIcon from "../../assets/menu/my-documents-icon.svg";
import MyRequestsIcon from "../../assets/menu/my-requests-icon.svg";
import MyScheduleIcon from "../../assets/menu/my-schedule-icon.svg";
import PayrollSummaryIcon from "../../assets/menu/payroll-summary-icon.svg";
import ProfileIcon from "../../assets/menu/profile-icon.svg";
import ReferralsIcon from "../../assets/menu/referrals-icon.svg";

// header menu import
// import More from "./headmenu/More";
import BellNotification from "./headmenu/BellNotification";
import SupportTicketing from "./headmenu/SupportTicketing";
import { menus } from "../../utils/constants";
// import Flags from "./headmenu/Flags";

const drawerWidth = 290;

const openedMixin = (theme) => ({
  width: drawerWidth,
  padding: "10px 0",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  padding: "10px 0",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: theme.spacing(11),
});

// const hideMixin = (theme) => ({
//   padding: "10px 0",
//   position: "absolute",
//   left: theme.spacing(-11),
//   transition: theme.transitions.create("left", {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.enteringScreen,
//   }),
// });

// const showMixin = (theme) => ({
//   padding: "10px 0",
//   position: "relative",
//   left: 0,
//   transition: theme.transitions.create("left", {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
// });

const CustomDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, hide }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  "& .MuiPaper-root": {
    backgroundColor: "white",
  },
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
  // ...(hide && {
  //   ...hideMixin(theme),
  //   "& .MuiDrawer-paper": hideMixin(theme),
  // }),
  // ...(!hide && {
  //   ...showMixin(theme),
  //   "& .MuiDrawer-paper": showMixin(theme),
  // }),
}));

const MenuLayout = ({ children }) => {
  const menu = getUser()?.menus || menus;
  const userFirstName = getUser()?.first_name || "";
  const userLastName = getUser()?.last_name || "";
  // const { menu } = data;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const companyProfile = useDataContext();
  // const { menuName, subName } = useParams();

  const matchMd = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const [isExpand, setExpand] = useState(false);
  const [hideMenu, setHideMenu] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState(null);
  const [activeMenu, setActiveMenu] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElSub, setAnchorElSub] = useState(null);
  const [anchorElSubSub, setAnchorElSubSub] = useState(null);

  const open = Boolean(anchorEl);
  const openSub = Boolean(anchorElSub);
  const openSubSub = Boolean(anchorElSubSub);
  const filter = "brightness(0) saturate(100%) invert(31%) sepia(49%) saturate(597%) hue-rotate(215deg) brightness(88%) contrast(102%)";

  const icons = {
    dashboard: <img src={DashboardIcon} width={isExpand ? "25px" : "35px"} />,
    profile: <img src={ProfileIcon} width={isExpand ? "25px" : "35px"} />,
    "my documents": <img src={MyDocumentsIcon} width={isExpand ? "25px" : "35px"} />,
    "my schedule": <img src={MyScheduleIcon} width={isExpand ? "25px" : "35px"} />,
    "attendance summary": <img src={AttendanceSummaryIcon} width={isExpand ? "25px" : "35px"} />,
    "file a request": <img src={FileRequestIcon} width={isExpand ? "25px" : "35px"} />,
    "my requests": <img src={MyRequestsIcon} width={isExpand ? "25px" : "35px"} />,
    lms: <img src={LmsIcon} width={isExpand ? "25px" : "35px"} />,
    reports: <ArticleIcon sx={{ fontSize: isExpand ? "24px" : "35px" }} />,
    referrals: <img src={ReferralsIcon} width={isExpand ? "25px" : "35px"} />,
    "offboarding clearance": <PersonRemoveIcon sx={{ fontSize: isExpand ? "24px" : "35px" }} />,
    "my payroll summary": <img src={PayrollSummaryIcon} width={isExpand ? "25px" : "35px"} />,
    "log out": <img src={LogoutIcon} width={isExpand ? "25px" : "35px"} />,
  };

  const clearLocalStorageAndLogout = () => {
    removeFromLocalStorage("yourCredentials");
    removeFromLocalStorage("st-admin-other-token");
    sessionStorage.clear();
    navigate("/login");
  };

  const handleLogOut = () => {
    Swal.fire({
      title: "Log Out",
      text: "User successfully log out.",
      // text: res.data.message,
      icon: "info",
      showConfirmButton: true,
    }).then(() => {
      clearLocalStorageAndLogout();
    });

    window.addEventListener('beforeunload', clearLocalStorageAndLogout);
    return () => {
      window.removeEventListener('beforeunload', clearLocalStorageAndLogout);
    };

  };

  // const getRouteName = (label) => {
  //   switch (label) {
  //     case "Dashboad":
  //       return "/";
  //     case ""
  //     default:
  //       return "/";
  //   }
  // };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSubMenuClose = () => setAnchorElSub(null);
  const handleSubSubMenuClose = () => setAnchorElSubSub(null);

  const handleExpand = () => {
    if (matchMd) {
      setHideMenu((prev) => !prev);
    }

    setExpand((prev) => !prev);

    const subMenuStatus = menu.map((mainMap) => ({
      [mainMap.id]: mainMap.menu.map((subMap) => ({
        isOpen: true,
        [subMap.id]: subMap.sub_menu.map((subSub) => ({
          isOpen: true,
          [subSub.id]:
            subSub?.sub_level?.map((subSubSub) => ({ isOpen: true })) || null,
        })),
      })),
    }));
    setSubMenuOpen(subMenuStatus);
    // setSubMenuOpen((prev) =>
    //   prev.map((item, i) => ({
    //     [Object.getOwnPropertyNames(item)]: item[
    //       Object.getOwnPropertyNames(item)
    //     ].map((sub) => ({ isOpen: !sub.isOpen })),
    //   }))
    // );
  };

  const handleMenuClick = (parentIndex, index, e) => {
    const hasSubMenu =
      menu[parentIndex].menu[index].sub_menu &&
        menu[parentIndex].menu[index].sub_menu.length > 0
        ? true
        : false;

    const menuLabel = replaceAndSpace(menu[parentIndex].menu[index].label);

    if (!isExpand) {
      if (!hasSubMenu) {
        sessionStorage.clear();
        navigate(menuLabel === "dashboard" ? "/" : menuLabel);
        // setActiveMenu((prev) => ({
        //   ...prev,
        //   main_parent_index: parentIndex,
        //   main_index: index,
        // }));
        handleMenuClose();
        handleSubMenuClose();

        return;
      }

      setActiveMenu((prev) => ({
        ...prev,
        main_parent_index: parentIndex,
        main_index: index,
        main: menu[parentIndex].menu[index].id,
      }));

      setAnchorEl(e.currentTarget);
      return;
    }

    if (!hasSubMenu) {
      sessionStorage.clear();
      navigate(menuLabel === "dashboard" ? "/" : menuLabel);
      return;
    }

    const parentId = menu[parentIndex].id;
    const myId = menu[parentIndex].menu[index].id;

    const isOpen = !subMenuOpen[parentIndex][parentId][index].isOpen;
    const menuInstance = subMenuOpen[parentIndex][parentId];
    const subMenuOpenInstance = [...subMenuOpen];

    menuInstance.splice(index, 1, {
      isOpen,
      [myId]: subMenuOpen[parentIndex][parentId][index][myId],
    });

    subMenuOpenInstance.splice(parentIndex, 1, { [parentId]: menuInstance });

    setSubMenuOpen(subMenuOpenInstance);
  };

  const handleSubMenuClick = (grandpaIndex, parentIndex, index, e) => {
    const hasSubSubMenu =
      menu[grandpaIndex].menu[parentIndex].sub_menu[index].sub_level &&
        menu[grandpaIndex].menu[parentIndex].sub_menu[index].sub_level.length > 0
        ? true
        : false;

    const menuLabel = replaceAndSpace(
      menu[grandpaIndex].menu[parentIndex].label
    );
    const subMenuLabel = replaceAndSpace(
      menu[grandpaIndex].menu[parentIndex].sub_menu[index].label
    );

    const mainId = menu[grandpaIndex].menu[parentIndex].id;
    const subId = menu[grandpaIndex].menu[parentIndex].sub_menu[index].id;

    if (!isExpand) {
      if (!hasSubSubMenu) {
        sessionStorage.clear();
        navigate(`/${menuLabel}/${subMenuLabel}`);
        handleMenuClose();
        handleSubMenuClose();
        return;
      }

      const grandpaId = menu[grandpaIndex].id;
      const parentId = menu[grandpaIndex].menu[parentIndex].id;
      const myId = menu[grandpaIndex].menu[parentIndex].sub_menu[index].id;

      const isOpen =
        !subMenuOpen[grandpaIndex][grandpaId][parentIndex][parentId][index]
          .isOpen;
      const myMenuInstance =
        subMenuOpen[grandpaIndex][grandpaId][parentIndex][parentId];
      const menuInstance = subMenuOpen[grandpaIndex][grandpaId];
      const subMenuOpenInstance = [...subMenuOpen];

      myMenuInstance.splice(index, 1, {
        isOpen,
        [myId]: myMenuInstance[index][myId],
      });
      menuInstance.splice(parentIndex, 1, {
        ...menuInstance[parentIndex],
        [parentId]: myMenuInstance,
      });

      subMenuOpenInstance.splice(grandpaIndex, 1, {
        [grandpaId]: menuInstance,
      });

      setSubMenuOpen(subMenuOpenInstance);

      setActiveMenu((prev) => ({
        ...prev,
        main_parent_index: grandpaIndex,
        main_index: parentIndex,
        sub_index: index,
        sub: subId,
        main: mainId,
      }));

      setAnchorElSub(e.currentTarget);
      return;
    }

    if (!hasSubSubMenu) {
      sessionStorage.clear();
      navigate(`/${menuLabel}/${subMenuLabel}`);
    }

    const grandpaId = menu[grandpaIndex].id;
    const parentId = menu[grandpaIndex].menu[parentIndex].id;
    const myId = menu[grandpaIndex].menu[parentIndex].sub_menu[index].id;

    const isOpen =
      !subMenuOpen[grandpaIndex][grandpaId][parentIndex][parentId][index]
        .isOpen;
    const myMenuInstance =
      subMenuOpen[grandpaIndex][grandpaId][parentIndex][parentId];
    const menuInstance = subMenuOpen[grandpaIndex][grandpaId];
    const subMenuOpenInstance = [...subMenuOpen];

    myMenuInstance.splice(index, 1, {
      isOpen,
      [myId]: myMenuInstance[index][myId],
    });
    menuInstance.splice(parentIndex, 1, {
      ...menuInstance[parentIndex],
      [parentId]: myMenuInstance,
    });

    subMenuOpenInstance.splice(grandpaIndex, 1, { [grandpaId]: menuInstance });

    setSubMenuOpen(subMenuOpenInstance);
  };

  const handleSubSubClick = (
    grandGrandPaIndex,
    grandPaIndex,
    parentIndex,
    index,
    e
  ) => {
    const hasSubSubSubMenu =
      menu[grandGrandPaIndex].menu[grandPaIndex].sub_menu[parentIndex]
        .sub_level[index].sub_level_2 &&
        menu[grandGrandPaIndex].menu[grandPaIndex].sub_menu[parentIndex]
          .sub_level[index].sub_level_2.length > 0
        ? true
        : false;

    const menuLabel = replaceAndSpace(
      menu[grandGrandPaIndex].menu[grandPaIndex].label
    );

    const subMenuLabel = replaceAndSpace(
      menu[grandGrandPaIndex].menu[grandPaIndex].sub_menu[parentIndex].label
    );

    const subSubMenuLabel = replaceAndSpace(
      menu[grandGrandPaIndex].menu[grandPaIndex].sub_menu[parentIndex]
        .sub_level[index].label
    );

    const mainId = menu[grandGrandPaIndex].menu[grandPaIndex].id;
    const subId =
      menu[grandGrandPaIndex].menu[grandPaIndex].sub_menu[parentIndex].id;
    const subSubId =
      menu[grandGrandPaIndex].menu[grandPaIndex].sub_menu[parentIndex]
        .sub_level[index].id;

    const grandgrandpaId = menu[grandGrandPaIndex].id;
    const grandpaId = menu[grandGrandPaIndex].menu[grandPaIndex].id;
    const parentId =
      menu[grandGrandPaIndex].menu[grandPaIndex].sub_menu[parentIndex].id;
    const myId =
      menu[grandGrandPaIndex].menu[grandPaIndex].sub_menu[parentIndex]
        .sub_level[index].id;

    if (!isExpand) {
      if (!hasSubSubSubMenu) {
        sessionStorage.clear();
        navigate(`/${menuLabel}/${subMenuLabel}/${subSubMenuLabel}`);
        handleMenuClose();
        handleSubMenuClose();
        handleSubSubMenuClose();
        return;
      }

      const isOpen =
        !subMenuOpen[grandGrandPaIndex][grandgrandpaId][grandPaIndex][
          grandpaId
        ][parentIndex][parentId][index].isOpen;
      const myMenuInstance =
        subMenuOpen[grandGrandPaIndex][grandgrandpaId][grandPaIndex][grandpaId][
        parentIndex
        ][parentId];
      const menuInstance = subMenuOpen[grandGrandPaIndex][grandgrandpaId];
      const subMenuInstance =
        subMenuOpen[grandGrandPaIndex][grandgrandpaId][grandPaIndex][grandpaId];
      const subMenuOpenInstance = [...subMenuOpen];

      myMenuInstance.splice(index, 1, { isOpen });
      subMenuInstance.splice(parentIndex, 1, {
        ...subMenuInstance[parentIndex],
        [parentId]: myMenuInstance,
      });
      menuInstance.splice(grandPaIndex, 1, {
        ...menuInstance[grandPaIndex],
        [grandpaId]: subMenuInstance,
      });

      subMenuOpenInstance.splice(grandGrandPaIndex, 1, {
        [grandgrandpaId]: menuInstance,
      });

      setSubMenuOpen(subMenuOpenInstance);

      setActiveMenu((prev) => ({
        ...prev,
        main_parent_index: grandGrandPaIndex,
        main_index: grandPaIndex,
        sub_index: parentIndex,
        sub_sub_index: index,
        sub: subId,
        main: mainId,
        sub_sub: subSubId,
      }));

      setAnchorElSubSub(e.currentTarget);
      return;
    }

    if (!hasSubSubSubMenu) {
      sessionStorage.clear();
      navigate(`/${menuLabel}/${subMenuLabel}/${subSubMenuLabel}`);
    }

    const isOpen =
      !subMenuOpen[grandGrandPaIndex][grandgrandpaId][grandPaIndex][grandpaId][
        parentIndex
      ][parentId][index].isOpen;
    const myMenuInstance =
      subMenuOpen[grandGrandPaIndex][grandgrandpaId][grandPaIndex][grandpaId][
      parentIndex
      ][parentId];
    const menuInstance = subMenuOpen[grandGrandPaIndex][grandgrandpaId];
    const subMenuInstance =
      subMenuOpen[grandGrandPaIndex][grandgrandpaId][grandPaIndex][grandpaId];
    const subMenuOpenInstance = [...subMenuOpen];

    myMenuInstance.splice(index, 1, { isOpen });
    subMenuInstance.splice(parentIndex, 1, {
      ...subMenuInstance[parentIndex],
      [parentId]: myMenuInstance,
    });
    menuInstance.splice(grandPaIndex, 1, {
      ...menuInstance[grandPaIndex],
      [grandpaId]: subMenuInstance,
    });

    subMenuOpenInstance.splice(grandGrandPaIndex, 1, {
      [grandgrandpaId]: menuInstance,
    });

    setSubMenuOpen(subMenuOpenInstance);
  };

  const handleSubSubSubClick = (
    grandGrandGrandPaIndex,
    grandGrandPaIndex,
    grandPaIndex,
    parentIndex,
    index
  ) => {
    const menuLabel = replaceAndSpace(
      menu[grandGrandGrandPaIndex].menu[grandGrandPaIndex].label
    );
    const subMenuLabel = replaceAndSpace(
      menu[grandGrandGrandPaIndex].menu[grandGrandPaIndex].sub_menu[
        grandPaIndex
      ].label
    );
    const subSubMenuLabel = replaceAndSpace(
      menu[grandGrandGrandPaIndex].menu[grandGrandPaIndex].sub_menu[
        grandPaIndex
      ].sub_level[parentIndex].label
    );
    const subSubSubMenuLabel = replaceAndSpace(
      menu[grandGrandGrandPaIndex].menu[grandGrandPaIndex].sub_menu[
        grandPaIndex
      ].sub_level[parentIndex].sub_level_2[index].label
    );

    sessionStorage.clear();
    navigate(
      `/${menuLabel}/${subMenuLabel}/${subSubMenuLabel}/${subSubSubMenuLabel}`
    );
  };

  const getActives = (pathname) => {
    const pathSplit = pathname.split("/").filter((item) => item !== "");
    const menuName = pathSplit[0] ? pathSplit[0] : undefined;
    const subName = pathSplit[1] ? pathSplit[1] : undefined;
    const subSubName = pathSplit[2] ? pathSplit[2] : undefined;
    const subSubSubName = pathSplit[3] ? pathSplit[3] : undefined;
    let main_parent_index = 0;
    let main_index = 0;
    let sub_index = 0;
    let sub_sub_index = 0;
    let sub_sub_sub_index = 0;
    let main = menuName === undefined ? menu[0]?.menu[0]?.id || 1 : null;
    let sub = null;
    let sub_sub = null;
    let sub_sub_sub = null;

    menu?.forEach((item, i) => {
      const menuMenu = item.menu.find(
        (itemMenu) => replaceAndSpace(itemMenu.label) === menuName
      );

      const mIndex = item.menu.findIndex(
        (itemMenu) => replaceAndSpace(itemMenu.label) === menuName
      );

      const subSub =
        menuMenu?.sub_menu && menuMenu?.sub_menu?.length > 0
          ? menuMenu?.sub_menu?.find(
            (itemSub) => replaceAndSpace(itemSub.label) === subName
          )
          : undefined;

      const sIndex =
        subSub === undefined
          ? 0
          : menuMenu?.sub_menu && menuMenu?.sub_menu?.length > 0
            ? menuMenu?.sub_menu?.findIndex(
              (itemSub) => replaceAndSpace(itemSub.label) === subName
            )
            : 0;

      const subSubSub =
        subSub?.sub_level && subSub?.sub_level.length > 0
          ? subSub?.sub_level.find(
            (itemSubSub) => replaceAndSpace(itemSubSub.label) === subSubName
          )
          : undefined;

      const ssIndex =
        subSubSub === undefined
          ? 0
          : subSub?.sub_level && subSub?.sub_level.length > 0
            ? subSub?.sub_level.findIndex(
              (itemSubSub) => replaceAndSpace(itemSubSub.label) === subSubName
            )
            : 0;

      const subSubSubSub =
        subSubSub?.sub_level_2 && subSubSub?.sub_level_2.length > 0
          ? subSubSub?.sub_level_2.find(
            (itemSubSub) =>
              replaceAndSpace(itemSubSub.label) === subSubSubName
          )
          : undefined;

      const sssIndex =
        subSubSubSub === undefined
          ? 0
          : subSubSubSub?.sub_level_2 && subSubSubSub?.sub_level_2.length > 0
            ? subSubSubSub?.sub_level_2.findIndex(
              (itemSubSub) =>
                replaceAndSpace(itemSubSub.label) === subSubSubName
            )
            : 0;

      if (menuMenu) {
        main_parent_index = i;
        main_index = mIndex;
        sub_index = sIndex;
        sub_sub_index = ssIndex || 0;
        sub_sub_sub_index = sssIndex || 0;
        main = menuMenu?.id || 0;
        sub = subSub?.id || 0;
        sub_sub = subSubSub?.id || 0;
        sub_sub_sub = subSubSubSub?.id || 0;
      }
    });

    return {
      main_parent_index,
      main_index,
      sub_index,
      sub,
      main,
      sub_sub_index,
      sub_sub,
      sub_sub_sub_index,
      sub_sub_sub,
    };
  };

  useEffect(() => {
    let actives = getActives(pathname);

    if (!subMenuOpen) {
      const subMenuStatus = menu?.map((mainMap) => ({
        [mainMap.id]: mainMap.menu.map((subMap) => ({
          isOpen: true,
          [subMap.id]: subMap.sub_menu.map((subSub) => ({
            isOpen: false,
            [subSub.id]:
              subSub?.sub_level?.map((subSubSub) => ({
                isOpen: false,
              })) || null,
          })),
        })),
      }));

      setSubMenuOpen(subMenuStatus);
    }

    setActiveMenu(actives);
  }, [pathname]);

  useEffect(() => {
    if (matchMd) {
      // setSubMenuOpen((prev) =>
      //   prev.map((item, i) => ({
      //     [`20${i + 1}`]: item[`20${i + 1}`].map((sub) => ({ isOpen: false })),
      //   }))
      // );
      const subMenuStatus = menu.map((mainMap) => ({
        [mainMap.id]: mainMap.menu.map((subMap) => ({
          isOpen: false,
          [subMap.id]: subMap.sub_menu.map((subSub) => ({
            isOpen: false,
            [subSub.id]:
              subSub?.sub_level?.map((subSubSub) => ({
                isOpen: false,
              })) || null,
          })),
        })),
      }));

      setSubMenuOpen(subMenuStatus);
      // setSubMenuOpen((prev) =>
      //   prev.map((item, i) => ({
      //     [Object.getOwnPropertyNames(item)]: item[
      //       Object.getOwnPropertyNames(item)
      //     ].map((sub) => ({ isOpen: false })),
      //   }))
      // );
      setHideMenu(true);
      setExpand(false);
    } else {
      const subMenuStatus = menu.map((mainMap) => ({
        [mainMap.id]: mainMap.menu.map((subMap) => ({
          isOpen: true,
          [subMap.id]: subMap.sub_menu.map((subSub) => ({
            isOpen: false,
            [subSub.id]:
              subSub?.sub_level?.map((subSubSub) => ({
                isOpen: false,
              })) || null,
          })),
        })),
      }));
      setSubMenuOpen(subMenuStatus);
      setExpand(false);
      setHideMenu(false);
    }
  }, [matchMd]);

  useEffect(() => {
    const userData = getUser();
    setToLocalStorage("yourCredentials", { ...userData, menus: menu });
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        sx={{
          borderRadius: 0,
          backgroundColor: "brandColor.500",
          minHeight: "80px",
          padding: 0,
          boxShadow: "none",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        position="fixed"
      >
        <Toolbar
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            minHeight: "80px !important",
          }}
        >
          <Stack direction="row" alignItems={"center"} spacing={2}>
            <Box
              sx={{
                width: isExpand ? "220px" : "67px",
                overflow: "hidden",
                transition: (theme) =>
                  isExpand
                    ? theme.transitions.create(["width"], {
                      easing: theme.transitions.easing.sharp,
                      duration: theme.transitions.duration.enteringScreen,
                    })
                    : theme.transitions.create(["width"], {
                      easing: theme.transitions.easing.sharp,
                      duration: theme.transitions.duration.leavingScreen,
                    }),
              }}
            >
              {isExpand ? (
                <img
                  src={
                    // companyProfile?.companyProfile !== null
                    //   ? "https://" + companyProfile?.companyProfile?.logo_white
                    //   : 
                    WhiteBrandLogo
                  }
                  style={{ width: 220 }}
                  loading="lazy"
                  alt="Satellite Teams"
                />
              ) : (
                <img
                  src={WhiteInitLogo}
                  style={{ width: 67 }}
                  loading="lazy"
                  alt="Satellite Teams"
                />
              )}
            </Box>

            <IconButton size={"medium"} onClick={handleExpand}>
              {isExpand ? (
                <MenuIcon
                  sx={{
                    fontSize: 30,
                    color: "common.white",
                  }}
                />
              ) : (
                <ArrowForwardIcon
                  sx={{
                    fontSize: 30,
                    color: "common.white",
                  }}
                />
              )}
            </IconButton>
          </Stack>
          <Typography
            variant="h5"
            fontWeight={500}
            sx={(theme) => ({
              WebkitBackgroundClip: "text !important",
              WebkitTextFillColor: "transparent",
              backgroundClip: "text",
              background: "linear-gradient(90deg, rgba(238, 235, 248, 0.94) -0.86%, #A36BFF 71.61%, rgba(236, 231, 248, 0.941452) 87.39%)",
              [theme.breakpoints.down("md")]: {
                display: "none",
              },
            })}
          >
            Welcome to Employee Portal
          </Typography>
          <Stack direction="row" alignItems="center">
            <SupportTicketing />
            <BellNotification />
            {/* <Typography
              variant="body1"
              fontSize=".8rem"
              sx={{
                color: "#d2cbe6",
              }}
              paddingRight={1}
            >
              John Doe{" "} */}
            <Typography
              variant="body1"
              fontSize=".9rem"
              fontWeight={500}
              component="span"
              sx={{
                ml: "4px",
                color: "#fff",
                textTransform: "uppercase"
              }}
            >
              {userFirstName + " " + userLastName}
            </Typography>
            {/* </Typography> */}
            {/* <More /> */}
            {/* <IconButton onClick={() => console.log("yes")}>
              <HelpOutlineIcon
                sx={{
                  fontSize: 25,
                  color: "common.white",
                }}
              />
            </IconButton> */}
            {/* <Flags /> */}
          </Stack>
        </Toolbar>
      </AppBar>
      {/* <Drawer
        variant="permanent"
        sx={{
          width: (theme) => (isExpand ? drawerWidth : theme.spacing(11)),
          left: hideMenu ? "-88px" : "0px",
          position: hideMenu ? "absolute" : "relative",
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: (theme) => (isExpand ? drawerWidth : theme.spacing(11)),
            left: hideMenu ? "-88px" : "0px",
            position: hideMenu ? "absolute" : "relative",
            boxSizing: "border-box",
            border: 0,
            overflowX: "hidden",
            padding: "10px 0",
            transition: (theme) =>
              isExpand || hideMenu
                ? theme.transitions.create(["width", "left"], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                  })
                : theme.transitions.create(["width", "left"], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                  }),
          },
        }}
      > */}
      <CustomDrawer
        variant="permanent"
        open={isExpand}
      // hide={hideMenu ? hideMenu : undefined}
      >
        <Toolbar />
        {/* no collapse and has link */}
        <Box
          sx={{
            overflowY: "auto",
            overflowX: "hidden",
            padding: "10px",
            marginTop: "10px",
          }}
        >
          {menu && (
            <>
              {menu?.map((item, i) => (
                <React.Fragment key={`${item.id}-${item.label}`}>
                  <List
                    sx={{
                      paddingTop: isExpand ? 4 : 0,
                      paddingBottom: isExpand ? 2 : 0,
                    }}
                    subheader={
                      isExpand && (
                        <ListSubheader
                          disableSticky
                          sx={{
                            color: "brandColor.700",
                            fontSize: ".65rem",
                            marginBottom: 2,
                            lineHeight: ".65rem",
                          }}
                        >
                          {item.label.toUpperCase()}
                        </ListSubheader>
                      )
                    }
                  >
                    {item.menu &&
                      item.menu.length > 0 &&
                      item.menu.map((main, ind) => (
                        <React.Fragment key={`${main.id}-${main.label}`}>
                          <ListItem
                            button
                            onClick={(e) => handleMenuClick(i, ind, e)}
                            id={ind}
                            aria-controls={open ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            sx={{
                              padding: isExpand ? "8px 16px" : "8px",
                              margin: isExpand ? 0 : "16px 0",
                              justifyContent: isExpand
                                ? "flex-start"
                                : "center",
                              backgroundColor: activeMenu?.main === main.id && "#F8EEFF",
                              borderRadius: "10px",
                            }}
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: 25,
                                color:
                                  activeMenu?.main === main.id
                                    ? "brandColor.600"
                                    : "grey.500",
                                "& img": {
                                  filter:
                                    activeMenu?.main === main.id
                                      ? filter
                                      : "",
                                  width: isExpand ? "24px" : "35px",
                                  margin: "0 auto",
                                }
                              }}
                            >
                              <Box
                                sx={{
                                  backgroundColor: "transparent",
                                  borderRadius: "2px",
                                  padding: "3px",
                                  height: isExpand ? "30px" : "40px",
                                }}
                              >
                                {icons[main?.label?.toLowerCase()]}
                              </Box>
                            </ListItemIcon>
                            {isExpand && (
                              <>
                                <ListItemText
                                  sx={{
                                    color:
                                      activeMenu?.main === main.id
                                        ? "brandColor.600"
                                        : "grey.500",
                                    marginLeft: 2,
                                    "& > span": {
                                      fontSize: ".75rem",
                                    },
                                  }}
                                >
                                  {main.label}
                                </ListItemText>
                                {main.sub_menu &&
                                  main.sub_menu.length > 0 &&
                                  subMenuOpen &&
                                  (subMenuOpen[i][item.id][ind].isOpen ? (
                                    <ExpandMoreIcon
                                      sx={{
                                        color:
                                          activeMenu?.main === main.id
                                            ? "brandColor.600"
                                            : "grey.500",
                                        fontSize: 20,
                                      }}
                                    />
                                  ) : (
                                    <KeyboardArrowRightIcon
                                      sx={{
                                        color:
                                          activeMenu?.main === main.id
                                            ? "brandColor.600"
                                            : "grey.500",
                                        fontSize: 20,
                                      }}
                                    />
                                  ))}
                              </>
                            )}
                          </ListItem>
                          {main.sub_menu &&
                            main.sub_menu.length > 0 &&
                            isExpand && (
                              <Collapse
                                in={
                                  subMenuOpen
                                    ? subMenuOpen[i][item.id][ind].isOpen
                                    : false
                                }
                                timeout="auto"
                                unmountOnExit
                              >
                                <List disablePadding>
                                  {main.sub_menu.map((sub, index) => (
                                    <React.Fragment
                                      key={`${sub.id}-${sub.label}`}
                                    >
                                      <ListItem
                                        onClick={(e) =>
                                          handleSubMenuClick(i, ind, index, e)
                                        }
                                        button
                                        sx={{
                                          padding: "3px 16px 3px 25px",
                                          backgroundColor: activeMenu?.sub === sub.id && "#F8EEFF",
                                          borderRadius: "10px",
                                        }}
                                      >
                                        <ListItemIcon
                                          sx={{
                                            minWidth: 14,
                                            color:
                                              activeMenu?.sub === sub.id
                                                ? "brandColor.600"
                                                : "grey.500",
                                          }}
                                        >
                                          <CircleIcon
                                            sx={{ fontSize: "7px" }}
                                          />
                                        </ListItemIcon>
                                        <ListItemText
                                          sx={{
                                            color:
                                              activeMenu?.sub === sub.id
                                                ? "brandColor.600"
                                                : "grey.500",
                                            "& > span": {
                                              fontSize: ".75rem",
                                              fontWeight: 500,
                                            },
                                          }}
                                        >
                                          {sub.label}
                                        </ListItemText>
                                        {sub.sub_level &&
                                          sub.sub_level.length > 0 &&
                                          subMenuOpen &&
                                          (subMenuOpen[i][item.id][ind][
                                            main.id
                                          ][index].isOpen ? (
                                            <ExpandMoreIcon
                                              sx={{
                                                color:
                                                  activeMenu?.sub === sub.id
                                                    ? "brandColor.600"
                                                    : "grey.500",
                                                fontSize: 20,
                                              }}
                                            />
                                          ) : (
                                            <KeyboardArrowRightIcon
                                              sx={{
                                                color:
                                                  activeMenu?.sub === sub.id
                                                    ? "brandColor.600"
                                                    : "grey.500",
                                                fontSize: 20,
                                              }}
                                            />
                                          ))}
                                      </ListItem>
                                      {sub.sub_level &&
                                        sub.sub_level.length > 0 &&
                                        isExpand && (
                                          <Collapse
                                            in={
                                              subMenuOpen
                                                ? subMenuOpen[i][item.id][ind][
                                                  main.id
                                                ][index].isOpen
                                                : false
                                            }
                                            timeout="auto"
                                            unmountOnExit
                                          >
                                            <List disablePadding>
                                              {sub.sub_level.map(
                                                (subSub, indexIndex) => (
                                                  <React.Fragment
                                                    key={`${subSub.id}-${subSub.label}`}
                                                  >
                                                    <ListItem
                                                      button
                                                      sx={{
                                                        padding:
                                                          "3px 16px 3px 40px",
                                                        backgroundColor: activeMenu?.sub_sub === subSub.id && "#F8EEFF",
                                                        borderRadius: "10px",
                                                      }}
                                                      onClick={() =>
                                                        handleSubSubClick(
                                                          i,
                                                          ind,
                                                          index,
                                                          indexIndex
                                                        )
                                                      }
                                                    >
                                                      <ListItemIcon
                                                        sx={{
                                                          minWidth: 14,
                                                          color:
                                                            activeMenu?.sub_sub ===
                                                              subSub.id
                                                              ? "brandColor.600"
                                                              : "grey.500",
                                                        }}
                                                      >
                                                        <CircleOutlinedIcon
                                                          sx={{
                                                            fontSize: "7px",
                                                          }}
                                                        />
                                                      </ListItemIcon>
                                                      <ListItemText
                                                        sx={{
                                                          color:
                                                            activeMenu?.sub_sub ===
                                                              subSub.id
                                                              ? "brandColor.600"
                                                              : "grey.500",
                                                          "& > span": {
                                                            fontSize: ".75rem",
                                                            fontWeight: 500,
                                                          },
                                                        }}
                                                      >
                                                        {subSub.label}
                                                      </ListItemText>
                                                      {subSub.sub_level_2 &&
                                                        subSub.sub_level_2
                                                          .length > 0 &&
                                                        subMenuOpen &&
                                                        (subMenuOpen[i][
                                                          item.id
                                                        ][ind][main.id][index][
                                                          sub.id
                                                        ][indexIndex].isOpen ? (
                                                          <ExpandMoreIcon
                                                            sx={{
                                                              color:
                                                                activeMenu?.sub_sub ===
                                                                  subSub.id
                                                                  ? "brandColor.600"
                                                                  : "grey.500",
                                                              fontSize: 20,
                                                            }}
                                                          />
                                                        ) : (
                                                          <KeyboardArrowRightIcon
                                                            sx={{
                                                              color:
                                                                activeMenu?.sub_sub ===
                                                                  subSub.id
                                                                  ? "brandColor.600"
                                                                  : "grey.500",
                                                              fontSize: 20,
                                                            }}
                                                          />
                                                        ))}
                                                    </ListItem>
                                                    {subSub.sub_level_2 &&
                                                      subSub.sub_level_2
                                                        .length > 0 &&
                                                      isExpand && (
                                                        <Collapse
                                                          in={
                                                            subMenuOpen
                                                              ? subMenuOpen[i][
                                                                item.id
                                                              ][ind][main.id][
                                                                index
                                                              ][sub.id][
                                                                indexIndex
                                                              ].isOpen
                                                              : false
                                                          }
                                                          timeout="auto"
                                                          unmountOnExit
                                                        >
                                                          <List disablePadding>
                                                            {subSub.sub_level_2.map(
                                                              (
                                                                subSubSub,
                                                                indexIndexIndex
                                                              ) => (
                                                                <ListItem
                                                                  key={`${subSubSub.id}-${subSubSub.label}`}
                                                                  button
                                                                  sx={{
                                                                    padding:
                                                                      "3px 16px 3px 60px",
                                                                    backgroundColor: activeMenu?.sub_sub_sub === subSubSub.id && "#F8EEFF",
                                                                    borderRadius: "10px",
                                                                  }}
                                                                  onClick={(
                                                                    e
                                                                  ) =>
                                                                    handleSubSubSubClick(
                                                                      i,
                                                                      ind,
                                                                      index,
                                                                      indexIndex,
                                                                      indexIndexIndex,
                                                                      e
                                                                    )
                                                                  }
                                                                >
                                                                  <ListItemIcon
                                                                    sx={{
                                                                      minWidth: 14,
                                                                      color:
                                                                        activeMenu?.sub_sub_sub ===
                                                                          subSubSub.id
                                                                          ? "brandColor.600"
                                                                          : "grey.500",
                                                                    }}
                                                                  >
                                                                    <CircleIcon
                                                                      sx={{
                                                                        fontSize:
                                                                          "6px",
                                                                      }}
                                                                    />
                                                                  </ListItemIcon>
                                                                  <ListItemText
                                                                    sx={{
                                                                      color:
                                                                        activeMenu?.sub_sub_sub ===
                                                                          subSubSub.id
                                                                          ? "brandColor.600"
                                                                          : "grey.500",
                                                                      "& > span":
                                                                      {
                                                                        fontSize:
                                                                          ".75rem",
                                                                        fontWeight: 500,
                                                                      },
                                                                    }}
                                                                  >
                                                                    {
                                                                      subSubSub.label
                                                                    }
                                                                  </ListItemText>
                                                                </ListItem>
                                                              )
                                                            )}
                                                          </List>
                                                        </Collapse>
                                                      )}
                                                  </React.Fragment>
                                                )
                                              )}
                                            </List>
                                          </Collapse>
                                        )}
                                    </React.Fragment>
                                  ))}
                                </List>
                              </Collapse>
                            )}
                        </React.Fragment>
                      ))}
                  </List>
                  {isExpand && <Divider />}
                </React.Fragment>
              ))}
              <List
                sx={{
                  paddingTop: isExpand ? 4 : 0,
                  paddingBottom: isExpand ? 2 : 0,
                }}
              >
                <ListItem
                  button
                  onClick={handleLogOut}
                  sx={{
                    padding: isExpand ? "8px 16px" : "8px",
                    margin: isExpand ? 0 : "16px 0",
                    justifyContent: isExpand ? "flex-start" : "center",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 25,
                      color: "grey.500",
                      "& img": {
                        width: isExpand ? "24px" : "35px",
                        margin: "0 auto",
                      }
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "transparent",
                        borderRadius: "2px",
                        padding: "3px",
                        height: isExpand ? "30px" : "40px",
                      }}
                    >
                      {icons["log out"]}
                    </Box>
                  </ListItemIcon>
                  {isExpand && (
                    <>
                      <ListItemText
                        sx={{
                          color: "grey.500",
                          marginLeft: 2,
                          "& > span": {
                            fontSize: ".75rem",
                          },
                        }}
                      >
                        Log Out
                      </ListItemText>
                    </>
                  )}
                </ListItem>
              </List>
              {!isExpand && activeMenu?.main_parent_index ? (
                <Menu
                  id={activeMenu.sub_index}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleMenuClose}
                  anchorOrigin={{
                    vertical: "center",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "center",
                    horizontal: "left",
                  }}
                  MenuListProps={{
                    "aria-labelledby": activeMenu.sub_index,
                  }}
                  sx={{
                    "& .MuiPaper-root": {
                      padding: 0,
                      borderRadius: 1,
                    },
                  }}
                >
                  {menu[activeMenu.main_parent_index].menu[
                    activeMenu.main_index
                  ].sub_menu.map((sub, index) => (
                    <div key={`${sub.id}-${sub.label}`}>
                      <MenuItem
                        sx={{
                          padding: "10px 40px",
                        }}
                        onClick={(e) =>
                          handleSubMenuClick(
                            activeMenu.main_parent_index,
                            activeMenu.main_index,
                            index,
                            e
                          )
                        }
                      >
                        <ListItemText>
                          <Typography
                            variant="body2"
                            sx={{
                              color:
                                activeMenu?.sub === sub.id
                                  ? "brandColor.600"
                                  : "grey.500",
                            }}
                          >
                            {sub.label}
                          </Typography>
                        </ListItemText>
                        {sub.sub_level && sub.sub_level.length > 0 && (
                          <KeyboardArrowRightIcon
                            sx={{
                              color: "grey.500",
                              fontSize: 25,
                              marginLeft: 2,
                            }}
                          />
                        )}
                      </MenuItem>
                    </div>
                  ))}
                </Menu>
              ) : null}
              {!isExpand &&
                (menu[activeMenu?.main_parent_index]?.menu[activeMenu.main_index]
                  ?.sub_menu[activeMenu.sub_index]?.sub_level ? (
                  <Menu
                    id={activeMenu.sub_sub_index}
                    anchorEl={anchorElSub}
                    open={openSub}
                    onClose={handleSubMenuClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "center",
                      horizontal: "left",
                    }}
                    MenuListProps={{
                      "aria-labelledby": activeMenu.sub_sub_index,
                    }}
                    sx={{
                      "& .MuiPaper-root": {
                        padding: 0,
                        borderRadius: 1,
                      },
                    }}
                  >
                    {menu[activeMenu.main_parent_index]?.menu[
                      activeMenu.main_index
                    ]?.sub_menu[activeMenu.sub_index]?.sub_level?.map(
                      (subSub, indexIndex) => (
                        <MenuItem
                          key={`${subSub.id}-${subSub.label}`}
                          sx={{
                            padding: "10px 40px",
                          }}
                          onClick={(e) =>
                            handleSubSubClick(
                              activeMenu.main_parent_index,
                              activeMenu.main_index,
                              activeMenu.sub_index,
                              indexIndex,
                              e
                            )
                          }
                        >
                          <ListItemText>
                            <Typography
                              variant="body2"
                              sx={{
                                color:
                                  activeMenu?.sub_sub === subSub.id
                                    ? "brandColor.600"
                                    : "grey.500",
                              }}
                            >
                              {subSub.label}
                            </Typography>
                          </ListItemText>
                          {subSub.sub_level_2 && subSub.sub_level_2.length > 0 && (
                            <KeyboardArrowRightIcon
                              sx={{
                                color: "grey.500",
                                fontSize: 25,
                                marginLeft: 2,
                              }}
                            />
                          )}
                        </MenuItem>
                      )
                    )}
                  </Menu>
                ) : null)}
              {!isExpand &&
                menu[activeMenu?.main_parent_index]?.menu[activeMenu.main_index]
                  ?.sub_menu[activeMenu.sub_index]?.sub_level[
                  activeMenu.sub_sub_index
                ]?.sub_level_2 ? (
                <Menu
                  id={activeMenu.sub_sub_sub_index}
                  anchorEl={anchorElSubSub}
                  open={openSubSub}
                  onClose={handleSubSubMenuClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "center",
                    horizontal: "left",
                  }}
                  MenuListProps={{
                    "aria-labelledby": activeMenu.sub_sub_sub_index,
                  }}
                  sx={{
                    "& .MuiPaper-root": {
                      padding: 0,
                      borderRadius: 1,
                    },
                  }}
                >
                  {menu[activeMenu.main_parent_index]?.menu[
                    activeMenu.main_index
                  ]?.sub_menu[activeMenu.sub_index]?.sub_level[
                    activeMenu.sub_sub_index
                  ]?.sub_level_2?.map((subSubSub, indexIndexIndex) => (
                    <MenuItem
                      key={`${subSubSub.id}-${subSubSub.label}`}
                      sx={{
                        padding: "10px 40px",
                      }}
                      onClick={() =>
                        handleSubSubSubClick(
                          activeMenu.main_parent_index,
                          activeMenu.main_index,
                          activeMenu.sub_index,
                          activeMenu.sub_sub_index,
                          indexIndexIndex
                        )
                      }
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          color:
                            activeMenu?.sub_sub_sub === subSubSub.id
                              ? "brandColor.600"
                              : "grey.500",
                        }}
                      >
                        {subSubSub.label}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              ) : null}
            </>
          )}
        </Box>
      </CustomDrawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          height: "100vh",
          overflowX: "hidden !important",
          overflowY: "visible !important",
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
};

export default MenuLayout;
